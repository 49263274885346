.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact-page form .input-group {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.25rem;
}

@media screen and (max-width: 768px) {
  .contact-page form .input-group {
    flex-direction: column;
  }
  .contact-page form {
    width: 100%;
  }
}
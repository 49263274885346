header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem 8rem;
  background: var(--darkblack);
  color: var(--white);
}
header .header-section {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
header .header-section.nav .active::after,
.routes-sidebar .active::after {
  content: "";
  display: block;
  width: 1rem;
  position: absolute;
  height: 0.1rem;
  background: var(--yellow);
}
.routes-sidebar .active::after {
  left: 50%;
  width: 2rem;
  transform: translateX(-50%);
}
header .header-section .user-dd-menu {
  display: none;
  position: absolute;
  z-index: 11;
  right: 7.5rem;
  top: 5rem;
  width: 9rem;
  background: var(--darkblack);
  border: 0.1rem solid var(--dark);
  border-radius: 0.25rem;
  border-top: none;
}
header .header-section .user-dd-menu-trigger:hover .user-dd-menu {
  display: block;
}
.routes-sidebar {
  position: fixed;
  width: 100%;
  background: var(--darkblack);
  overflow: hidden;
  transition: height ease-in-out 0.5s;
  z-index: 99;
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.routes-sidebar.show {
  height: calc(100vh - 3rem);
}

.page-layout {
  padding: 4rem 8rem;
  min-height: calc(100vh - 8rem);
  transition: all 0.2s ease-in-out;
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 8rem;
  color: var(--lightgrey3);
  background: var(--darkblack2);
}
footer .route-item {
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;
}
footer .route-item:hover {
  color: var(--yellow);
}

@media screen and (max-width: 1440px) {
  header {
    padding: 1rem 6rem;
  }
  header .header-section .user-dd-menu {
    right: 5.5rem;
  }
  .page-layout {
    padding: 4rem 6rem;
  }
  footer {
    padding: 1rem 6rem;
  }
}
@media screen and (max-width: 1280px) {
  header {
    padding: 1rem 4rem;
  }
  header .header-section .user-dd-menu {
    right: 3.5rem;
  }
  .page-layout {
    padding: 4rem 4rem;
  }
  footer {
    padding: 1rem 4rem;
  }
}
@media screen and (max-width: 1024px) {
  header .header-section {
    gap: 1rem;
  }
}
@media screen and (max-width: 950px) {
  header {
    padding: 1rem 2rem;
    gap: 1rem;
  }
  header .header-section {
    gap: 1rem;
  }
  header .header-section .user-dd-menu {
    right: 1.5rem;
  }
  .page-layout {
    padding: 4rem 2rem;
  }
  footer {
    padding: 1rem 2rem;
  }
  footer .route-item {
    padding: 0.5rem 0.75rem;
  }
}
@media screen and (max-width: 900px) {
  header .header-section.nav {
    display: none;
  }
  header .header-section {
    gap: 1.5rem;
  }
}
@media screen and (max-width: 640px) {
  .routes-sidebar.show {
    padding-top: 2rem;
  }
}
@media screen and (max-width: 480px) {
  footer .route-item {
    padding: 0.5rem 0.5rem;
  }
  header {
    padding: 1rem 1rem;
  }
  .page-layout {
    padding: 4rem 1rem;
  }
  footer {
    padding: 1rem 1rem;
  }
}

.btn {
  text-align: center;
  transition: 0.3s all ease-in-out;
}
.btn.btn-yellow {
  background-color: var(--yellow);
  color: var(--darkblack);
  font-weight: 600;
}
.btn.btn-yellow:hover {
  background-color: var(--yellow2);
}
.btn.btn-dark {
  background-color: var(--dark);
  color: var(--white);
  border: solid 1px var(--black);
}
.btn.btn-dark:hover {
  border-color: var(--yellow);
}
.btn.btn-dark-noborder {
  background-color: var(--dark);
  color: var(--white);
}
.btn[disabled] {
  background-color: var(--lightgrey3);
  color: var(--black);
  cursor: default;
}
.btn[disabled]:hover {
  background-color: var(--lightgrey3);
  color: var(--black);
}
.btn.btn-grey {
  background-color: var(--lightgrey3);
  color: var(--darkgrey);
}
.btn.btn-lg {
  padding: 0.75rem 1.5rem;
}
.btn.btn-md {
  padding: 0.75rem 1rem;
}
.btn.btn-md-rect {
  padding: 0.5rem 1rem;
}
.btn.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.88rem;
}
.btn.btn-rounded-md {
  border-radius: 0.5rem;
}
.btn.btn-rounded-sm {
  border-radius: 0.25rem;
}
.btn.btn-google-white {
  background-color: var(--white);
  color: var(--darkblack);
}
.btn.btn-google-white:hover {
  background-color: var(--lightgrey2);
}
.btn.btn-facebook-blue {
  background-color: #1877F2;
  color: var(--white);
}
.btn.btn-facebook-blue:hover {
  background-color: #1e5a9d;
}
.btn.btn-apple-black {
  background-color: var(--darkblack2);
  color: var(--white);
  border: solid 1px var(--black);
}
.btn.btn-apple-black:hover {
  background-color: var(--black);
}